<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品分类</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card>
      <!--   添加分类   -->
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <el-cascader
              :options="cateAlllist" @change="cateAllChange" placeholder="请选择查找分类"
              :props="cateAlllistProps" v-model="cateAllselect"
              clearable></el-cascader>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <el-input placeholder="请输入搜索内容" v-model="querInfo.c_name" clearable @clear="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-button type="primary" @click="showAddCateDialog" icon="el-icon-document-add" v-if="is_add">添加</el-button>
          <el-button type="primary" @click="editdiscount" icon="el-icon-document-add" v-if="is_add">编辑利率</el-button>
        </el-col>
      </el-row>
      <!--表格-->
      <!--分页-->
      <el-table :data="catelist" v-loading="loading" stripe @select-all="changeFun" @select="asd">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="分类名称" prop="c_name"></el-table-column>
        <el-table-column label="主图" width="105px">
          <template slot-scope="scope">
            <img :src="scope.row.c_icon" alt="" style="width: 80px;height: 80px;">
          </template>
        </el-table-column>
        <el-table-column label="是否有效">
          <template  slot-scope="scope">
            <el-switch v-model="scope.row.c_status" @change="goodsStateChanged(scope.row)" ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="层级">
          <template  slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.c_lev===1">一级</el-tag>
            <el-tag type="success" size="mini" v-else-if="scope.row.c_lev===2">二级</el-tag>
            <el-tag type="warning" size="mini" v-else>三级</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="c_sort"> </el-table-column>
        <el-table-column label="操作" width="200px">
          <template  slot-scope="scope">
            <el-button type="primary"  size="mini" v-if="is_edit" icon="el-icon-edit"
                       @click="editCateList(`${scope.row.c_id}`,`${scope.row.c_lev}`)"></el-button>
            <el-button type="danger"  size="mini" v-if="is_del" icon="el-icon-delete-solid"
                       @click="deleteCateList(`${scope.row.c_id}`)"></el-button>
          </template>
        </el-table-column>

      </el-table>
    </el-card>
    <!-- 添加分类对话框-->
    <el-dialog
        title="添加分类" :visible.sync="addCateDialogVisible" width="50%" @close="addCateDialogClose">
      <!--    添加分类的表单    -->
      <el-form :model="addCateForm" :rules="addCateFormRules" ref="addCateFormRef" label-width="100px">
        <el-form-item label="分类名称：" prop="c_name">
          <el-input v-model="addCateForm.c_name"></el-input>
        </el-form-item>
        <el-form-item label="分类简介：" prop="c_remarl">
          <el-input v-model="addCateForm.c_remarl"></el-input>
        </el-form-item>
        <el-form-item label="分类排序：" prop="c_sort">
          <el-input v-model="addCateForm.c_sort"></el-input>
        </el-form-item>
        <el-form-item label="分类利润：" prop="c_discount" v-if="ProfitInputVis==true">
          <el-input v-model="addCateForm.c_discount"></el-input>
        </el-form-item>
<!--        <el-form-item label="分类排序：" prop="c_sort">-->
<!--          <el-input v-model="addCateForm.c_sort"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="父级分类：" prop="selectefKeys">
          <!-- options:数据源        -->
          <el-cascader v-model="selectefKeys" :options="cateAlllist" clearable change-on-select
                       :props="cascaderProps" @change="parentCateChanged"></el-cascader>
        </el-form-item>
        <el-form-item label='上传图片'  prop="c_icon">
          <el-upload class="avatar-uploader" :headers="headersObj"
                     :action="action" :show-file-list="false"
                     :on-success="addhandleAvatarSuccess" >
            <div v-if="addCateForm.c_icon_see" class="upload-avatar">
              <img  :src="addCateForm.c_icon_see">
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div  v-if="addCateForm.c_icon_see" class="upload-delete" @click="handleRemove">
            <i class="el-icon-close"></i>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCate" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑分类对话框 -->
    <el-dialog
        title="编辑分类" :visible.sync="editDialogVisible" width="50%" @close="editCateDialogClose">
      <!--    添加分类的表单    -->
      <el-form :model="editCateForm" :rules="editCateFormRules"  ref="editCateFormRef"  label-width="100px">
        <el-form-item label="分类名称：" prop="c_name">
          <el-input v-model="editCateForm.c_name"></el-input>
        </el-form-item>
        <el-form-item label="分类简介：" prop="c_remarl">
          <el-input v-model="editCateForm.c_remarl"></el-input>
        </el-form-item>
        <el-form-item label="分类排序：" prop="c_sort">
          <el-input v-model="editCateForm.c_sort"></el-input>
        </el-form-item>
        <el-form-item label="分类利润：" prop="c_discount" v-if="editProfitInputVis">
          <el-input v-model="editCateForm.c_discount"></el-input>
        </el-form-item>
        <el-form-item label="父级分类：">
          <el-cascader v-model="editCateForm.pids" :options="cateAlllist" clearable change-on-select
                       :props="cascaderProps" @change="editparentCateChanged"></el-cascader>
        </el-form-item>
        <el-form-item label='上传图片' prop="c_icon">
          <el-upload class="avatar-uploader" :headers="headersObj"
                     :action="action" :show-file-list="false"
                     :on-success="edithandleAvatarSuccess" >
            <div v-if="editCateForm.c_icon_see" class="upload-avatar">
              <img :src="editCateForm.c_icon_see" alt="">
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div  v-if="editCateForm.c_icon_see" class="upload-delete" @click="edithandleRemove">
            <i class="el-icon-close"></i>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editCate" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="编辑分类利率" :visible.sync="editdiscountDialogVisible" width="50%" @close="editdiscountDialogClose">
      <el-form :model="editdisform" label-width="100px">
        <el-form-item label="分类利润：" prop="c_discount">
          <el-input v-model="editdisform.c_discount"></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button @click="editdiscountDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editdiscountloading" >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addloading:false,
      editloading:false,
      disabled: false,
      querInfo: {
        c_pid:'0',
        c_name:'',
      },
      //商品分类的数据列表，默认为空
      catelist: [],
      cateAlllist:[],
      //总数据条数
      total: 0,
      //页面权限
      CateJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      cateAlllistProps:{
        expandTrigger:'hover',
        checkStrictly: true,
        value:'c_id',
        label:'c_name',
        children: 'children'
      },
      cateAllselect:[],
      //指定级联选择器的配置对象
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      //控制添加分类对话框的显示和隐藏
      addCateDialogVisible: false,
      //添加分类的表单数据对象
      addCateForm: {
        //将要添加的分类的名称
        c_name: '',
        //父级分类的id
        c_pid: 0,
        //分类简介
        c_remarl: '',
        //分类排序
        c_sort: 0,
        //分类图片
        c_icon:'',
        //分类图片 展示
        c_icon_see:''
      },
      //添加分类表单的验证规则对象
      addCateFormRules: {
        c_name: [
          {required: true, message: '请输入分类的名称', trigger: 'blur'},
        ],
        // c_remarl: [
        //   {required: true, message: '请输入分类的简介', trigger: 'blur'},
        // ],
        // c_icon: [
        //   {required: true,message: '请上传图片', trigger: 'change'}
        // ],
        c_sort: [
          {required: true,message: '请输入分类排序', trigger: 'blur'}
        ],
      },
      editCateFormRules: {
        c_name: [
          {required: true, message: '请输入分类的名称', trigger: 'blur'},
        ],
        // c_remarl: [
        //   {required: true, message: '请输入分类的简介', trigger: 'blur'},
        // ],
        // c_icon: [
        //   {required: true,message: '请上传图片', trigger: 'change'}
        // ],
        c_sort: [
          {required: true,message: '请输入分类排序', trigger: 'blur'}
        ],

      },

      //选中的父级分类的ID数组
      selectefKeys: [],
      fileList: [],
      action:process.env.VUE_APP_BASE_API+'/upload/category',
      //控制编辑分类对话框的显示和隐藏
      editDialogVisible: false,
      //编辑分类的数据
      editCateForm:{
        c_icon:'',
        //分类图片 展示
        c_icon_see:''
      },
      //图片上传的请求头对象
      headersObj:{
        Authorization:'Bearer ' + window.sessionStorage.getItem('token')
      },
      //编辑分类图片的默认值
      imges:[{'url':''}],
      ProfitInputVis:false,
      editProfitInputVis:false,
      selectlist:[],
      editdisform:{
        cidlist:[],
        c_discount:''
      },
      editdiscountDialogVisible:false,
    }
  },
  created() {
    this.getCatecidList()
    this.getCateJurisdiction()
    this.getCateAllList()
  },
  methods: {
    //获取权限接口
    async getCateJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.CateJurisdiction=res.data
      this.CateJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    //获取商品分类数据
    async getCateAllList() {
      const {data: res} = await this.$http.get('category/getMainList/2')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.cateAlllist = res.data
    },
    //获取商品分类数据
    async getCateList() {
      this.loading=true
      const {data: res} = await this.$http.get('category/getMainList/1',
          {params: this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值给catelist
      this.catelist = res.data
      //把数据赋值给total
      // this.total = res.data.total
      this.loading=false
    },
    //获取
    async getCatecidList(id){
      let that=this
      this.loading=true
      const {data: res} = await this.$http.get('category/getAllList',
          {params: that.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.catelist = res.data
      this.loading=false
    },
    getlist(){
      this.getCateAllList()
      if (this.querInfo.c_pid){
        this.getCatecidList()
      }else{
        this.getCateList()
      }
    },
    asd(selection, row){
      this.selectlist=selection
    },
    changeFun(arr){
      this.selectlist=arr
    },
    editdiscount(){
      let array=[],flag
      if (this.selectlist.length!=0){
        this.selectlist.forEach(item=>{
          if (item.c_lev==3){
            array.push({c_id:item.c_id})
          }else{
            flag='no'
          }
        })
      }else{
        flag='kong'
      }
      if (flag=='no')this.$message.warning('请选择三级分类')
      else if(flag=='kong')this.$message.warning('请选择需要修改的三级分类')
      else{
        this.editdisform.cidlist=array
        this.editdiscountDialogVisible=true
      }
    },
    editdiscountDialogClose(){
      this.editdisform.c_discount=''
    },
    async editdiscountloading(){
      const {data: res} = await this.$http.post(`category/batchEditDiscount`,this.editdisform)
      if(res.code != 200){
        return this.$message.error('修改利率失败')
      }
      this.$message.success('修改利率成功')
      this.editdiscountDialogVisible=false
      this.getCatecidList()
    },
    async goodsStateChanged(row){
      const {data: res} = await this.$http.post(`category/editStatus`,{
        'c_id':row.c_id,
        'c_status':row.c_status
      })
      if (res.code != 200) {
        row.c_status = !row.c_status
        return this.$message.error('更新状态失败')
      }
      this.$message.success('更新状态成功')
      this.getCatecidList()
    },
    cateAllChange(){
      // this.querInfo.page=1
      if (this.cateAllselect.length==0){
        this.querInfo.c_pid=0
        this.cateAllselect=[]
        // this.getCatecidList()
      }else if(this.cateAllselect.length==1){
        this.querInfo.c_pid=this.cateAllselect[0]
      }else if(this.cateAllselect.length==2){
        this.querInfo.c_pid=this.cateAllselect[1]
      }
      this.getCatecidList()
    },
    inputchange(){
      // this.querInfo.page=1
      this.getCatecidList()
    },
    //点击按钮 展示添加分类的对话框
    showAddCateDialog() {
      //先获取父级分类的数据列表
      // this.getParentCateList()
      //在展示出对话框
      this.addCateDialogVisible = true
    },
    //添加分类的图片删除
    handleRemove(){
      this.addCateForm.c_icon=''
      this.addCateForm.c_icon_see=''
    },
    //编辑分类的图片删除
    edithandleRemove(){
      this.editCateForm.c_icon=''
      this.editCateForm.c_icon_see=''
    },

    //选择项发生变化触发这个函数
    parentCateChanged() {
      //如果 selectedKeys 数据中的length 大于0 证明选中了父级分类 反之 就说明没有选中父级分类
      if (this.selectefKeys.length > 0) {
        console.log('if')
        //父级分类的id
        this.addCateForm.c_pid = this.selectefKeys[this.selectefKeys.length - 1]
        //为当前分类的等级赋值
        this.addCateForm.c_lev = this.selectefKeys.length
        return
      } else if(this.selectefKeys.length==1){
        console.log('else if')
        this.ProfitInputVis=false
      }else {
        this.addCateForm.c_pid = 0
        this.addCateForm.c_lev = 0
      }
    },
    //点击按钮添加新的分类
    addCate() {
      this.$refs.addCateFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('category/add', this.addCateForm)
        this.addloading=false
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success('添加分类成功')
        this.getlist()
        this.addCateDialogVisible = false
      })
    },
    addoneCateList(id,array,cpev){
      if (cpev==1){
        this.ProfitInputVis=true
      }else{
        this.ProfitInputVis=false
      }
      this.getParentCateList()
      this.selectefKeys = array.split(",").map(Number);
      this.addCateDialogVisible = true
      // this.selectefKeys=array
      this.addCateForm.c_pid=id

    },
    //监视对话框的关闭事件
    addCateDialogClose() {
      this.$refs.addCateFormRef.resetFields()
      this.selectefKeys = []
      this.addCateForm.c_pid = 0
      // this.addCateForm.c_lev = 0
      this.addCateForm.c_name = ''
      this.addCateForm.c_remarl = ''
      this.addCateForm.c_icon = ''
      this.addloading=false
    },
    editCateDialogClose(){
      this.$refs.editCateFormRef.resetFields()
      this.editCateForm={}
      this.editCateForm.pids=[]
      this.editloading=false
    },
    //监听图片上传成功的事件
    addhandleAvatarSuccess(response){
      this.addCateForm.c_icon=response.data.saveurl
      this.addCateForm.c_icon_see=response.data.url
    },
    //监听图片上传成功的事件
    edithandleAvatarSuccess(response){
      this.editCateForm.c_icon=response.data.saveurl
      this.editCateForm.c_icon_see=response.data.url
      console.log(this.editCateForm)
    },
    //编辑分类通过id获取数据
    async editCateList(id,cpev){
      this.editCateForm ={}
      this.editCateForm.pids =[]
      if (cpev==1){
        this.editProfitInputVis=false
      }else{
        this.editProfitInputVis=true
      }
      const {data: res} = await this.$http.get(`category/find/${id}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.editCateForm = res.data
      // this.$message.success('编辑用户信息成功')
      // this.getParentCateList()
      this.editDialogVisible = true
      // this.imges[0].url=this.editCateForm.c_icon
    },

    editparentCateChanged(){
      //如果 selectedKeys 数据中的length 大于0 证明选中了父级分类 反之 就说明没有选中父级分类
      if (this.editCateForm.pids.length > 0) {

        //父级分类的id
        this.editCateForm.c_pid = this.editCateForm.pids[this.editCateForm.pids.length - 1]
        //为当前分类的等级赋值
        this.editCateForm.c_lev = this.editCateForm.pids.length
        return
      } else {
        this.editCateForm.c_pid = 0
        this.editCateForm.c_lev = 0
      }
    },
    //点击确定按钮提交编辑的分类
    async editCate(){
      const confirmResult = await this.$confirm(
          '此操作将编辑该分类, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消编辑')
      }
      this.$refs.editCateFormRef.validate(async valid => {
        if (!valid) return
        this.editloading=true
        const {data: res} = await this.$http.post('category/edit', this.editCateForm)
        this.editloading=false
        if (res.code != 200) {
          return this.$message.error(res.msg)
        }
        console.log(this.editCateForm)
        this.$message.success('编辑分类成功')
        this.getlist()
        this.editDialogVisible = false
      })
    },
    //删除分类
    async deleteCateList(id){
      //弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
          '此操作将永久删除该分类, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data: res} = await this.$http.post(`category/del/${id}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.$message.success('删除分类成功')
      this.getlist()
    }
  }
}
</script>

<style lang="less" scoped>

.treetable {
  margin: 15px 0;
}

.el-cascader {
  width: 100%;
}
.el-col{
  margin-bottom: 20px;
}

.el-upload-list__item-preview{

}
</style>
